import { Card, ECardBorderRadiusSize } from '@outdoorsyco/bonfire';
import { clsx } from 'clsx';
import escapeHtml from 'escape-html';
import React, { AnchorHTMLAttributes, HTMLAttributeAnchorTarget } from 'react';

import Anchor from '@/components/switchback/Anchor/Anchor';
import Link from '@/components/switchback/Link/Link';
import { ISearchResultTile } from '@/utility/mapSearchResultToTile';

import { HorizontalListingTileContent } from './HorizontalListingTileContent';
import { ListingTileContent } from './ListingTileContent';
import { ListingTileMedia } from './ListingTileMedia';

export enum EListingTileVariant {
  Horizontal = 'horizontal',
  HorizontalBorderless = 'horizontal-borderless',
  Vertical = 'vertical',
}

type TListingTileProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  rentalTile: ISearchResultTile;
  isOutlined?: boolean;
  showFirstImageOnly?: boolean;
  addFavorite?: (id: number) => void;
  removeFavorite?: (id: number) => void;
  onSlideChange?: (nextIndex: number) => void;
  variant?: EListingTileVariant;
  displayStationaryCamperPromo?: boolean;
  customRentalTitle?: string;
  showProBadge?: boolean;
  showGuestFavorite?: boolean;
  withPriority?: boolean;
  withFetchPriority?: 'high' | 'low' | undefined;
  isNewHomepage?: boolean;
  target?: HTMLAttributeAnchorTarget | undefined;
  dataTestId?: string;
};

export const ListingTile = ({
  rentalTile,
  isOutlined,
  showFirstImageOnly,
  addFavorite,
  removeFavorite,
  onSlideChange,
  variant = EListingTileVariant.Vertical,
  displayStationaryCamperPromo,
  customRentalTitle = '',
  showGuestFavorite = false,
  showProBadge = false,
  withPriority = false,
  withFetchPriority,
  isNewHomepage = false,
  target,
  dataTestId,
  ...props
}: TListingTileProps) => {
  const escapedTitle = rentalTile.title && escapeHtml(rentalTile.title);
  const isHorizontal = variant === EListingTileVariant.Horizontal;
  const isHorizontalBorderless = variant === EListingTileVariant.HorizontalBorderless;

  let card = (
    <Card
      borderRadiusSize={ECardBorderRadiusSize.Large}
      showHoverEffect={false}
      withBoxShadow={false}
      withTransparentBackground={true}
      aria-label={displayStationaryCamperPromo ? customRentalTitle : escapedTitle}
      title={displayStationaryCamperPromo ? customRentalTitle : escapedTitle}
      className={clsx({
        '!border-neutral-20 !border-solid !border': isHorizontal,
        'outline outline-2 outline-offset-2 outline-green-500': isOutlined && isHorizontal,
      })}
      {...props}>
      <div
        className={clsx('flex', {
          'flex-row': isHorizontal || isHorizontalBorderless,
          'flex-col': !isHorizontal && !isHorizontalBorderless,
          'gap-4 items-center': isHorizontalBorderless,
        })}>
        <div
          className={clsx({
            'w-2/5 rounded-l-2xl': isHorizontal,
            'rounded-2xl': !isHorizontal,
          })}>
          <ListingTileMedia
            rentalTile={rentalTile}
            isOutlined={isOutlined && !isHorizontal}
            showFirstImageOnly={showFirstImageOnly}
            addFavorite={addFavorite}
            removeFavorite={removeFavorite}
            onSlideChange={onSlideChange}
            showLimitedOfferTag={displayStationaryCamperPromo}
            isHorizontal={isHorizontal}
            isHorizontalBorderless={isHorizontalBorderless}
            showGuestFavorite={showGuestFavorite}
            withPriority={withPriority}
            withFetchPriority={withFetchPriority}
            isNewHomepage={isNewHomepage}
          />
        </div>

        {isHorizontal ? (
          <HorizontalListingTileContent
            rentalTile={rentalTile}
            customTitle={displayStationaryCamperPromo ? customRentalTitle : ''}
          />
        ) : (
          <ListingTileContent
            rentalTile={rentalTile}
            customTitle={displayStationaryCamperPromo ? customRentalTitle : ''}
            showProBadge={showProBadge}
          />
        )}
      </div>
    </Card>
  );

  if (displayStationaryCamperPromo) {
    card = <div className="p-4 bg-green-30 rounded-2xl">{card}</div>;
  }

  if (rentalTile.linkUrl) {
    return (
      <Link href={rentalTile.linkUrl} legacy passHref>
        <Anchor
          href={rentalTile.linkUrl}
          target={target}
          data-testid={dataTestId}
          onClick={props.onClick}>
          {card}
        </Anchor>
      </Link>
    );
  }

  return card;
};
